import React from 'react'

import { Card } from '../../../components/Card'
import { Carousel } from '../../../components/Carousel'
import { TextGroup } from '../../../components/TextGroup'
import { TextWithImage } from '../../../components/TextWithImage'
import { Title } from '../../../components/Title'
import { VariantsContainer } from '../../../components/VariantsContainer'

import { List } from './components/List'

import { CopyAndPaste } from '..'

import InterrabangImg from './assets/interrobang.svg'
import slide11 from './assets/slide-1-1.webp'
import slide12 from './assets/slide-1-2.webp'
import slide21 from './assets/slide-2-1.webp'
import slide22 from './assets/slide-2-2.webp'
import slide31 from './assets/slide-3-1.webp'
import slide32 from './assets/slide-3-2.webp'

import styles from './styles.module.scss'

const variants = [
  { name: 'Interrobang', text: '‽' },
  { name: 'Inverted interrobang', text: '⸘' },
  { name: 'Question exclamation mark', text: '⁈' },
  { name: 'Exclamation question mark', text: '⁉' },
  { name: 'Heavy exclamation mark', text: '❢' },
  { name: 'Reverse question mark', text: '⸮' },
  { name: 'Inverted question mark', text: '¿' },
  { name: 'Arabic question mark', text: '؟' },
  { name: 'Small question mark', text: '﹖' }
]

const tags = [
  { name: 'Unicode' },
  { name: 'Tools' },
  { name: 'Symbols' },
  { name: 'Copy and paste' },
  { name: 'Dingbats' },
  { name: 'Punctuation' }
]

const Interrobang: React.FC = () => {
  return (
    <CopyAndPaste tags={tags} subtitleClassName={styles.subtitle} titleClassName={styles.title}>
      <VariantsContainer>
        {variants.map(variant => <Card className={styles.card} key={variant.name} title={variant.name} text={variant.text} textFontSize={32} textAlignCenter />)}
      </VariantsContainer>
      <TextWithImage
        title='What is an interrobang‽'
        subtitle='An interrobang is a combination of a question mark and an exclamation mark.'
        image={<InterrabangImg />}
        style={{ marginTop: 64 }}
      >
        <>
          <p>It allows you to add spark to a written question, make it clear that your question is rhetorical, or express surprise, skepticism, or irony.</p>
          <p>Its name is a portmanteau of interrogate and bang. (Bang is what typesetters used to call an exclamation mark.)</p>
        </>
      </TextWithImage>
      <TextGroup title='But what’s wrong with “!?” or “?!”' style={{ marginTop: 48 }}>
        Absolutely nothing — some people just think it’s messy to use multiple marks when one could do.
      </TextGroup>
      <TextGroup title='When to use an interrobang' style={{ marginTop: 48 }}>
        <p>Most of the time, the context or phrasing of a question is enough to understand its intent:</p>
        <i>If you tickle us, do we not laugh?</i>
        <p>It’s clear that this question is rhetorical.</p>
        <p>But, when context is limited — say, in an advertisement or a social media post — it can be useful to use an interrobang for clarity.</p>
        <p>
          For example, the following question has different meanings based on which type of punctuation is used.
          The first is literal, while the second is rhetorical.
        </p>
        <p>What are you doing?</p>
        <p>What are you doing‽</p>
      </TextGroup>
      <TextGroup title='The origin of the interrobang' style={{ marginTop: 48 }}>
        <p>The interrobang was invented by Martin Speckter, a classic New York adman with a passion for typography.</p>
        <p>
          He disliked the growing trend of using “!?” in advertising copy.
          Being an adman and the editor of a typography magazine, he found himself in a unique position to fix the problem — and so the interrobang was born.
        </p>
        <p>Here’s an example that Speckter used himself:</p>
        <i>What? A refrigerator that makes its own ice cubes‽</i>
        <p>
          Speckter proposed his invention to his readers, and it was a hit.
          They offered up some fun alternative names, like quizding, rhet, exclamaquest, emphaquest, and exclarogative, but Speckter’s original name stuck.
        </p>
      </TextGroup>
      <TextGroup title='Designing the interrobang' style={{ marginTop: 48 }}>
        <p>
          Speckter’s readers were also asked to volunteer designs.
          But in the end, the interrobang designed itself. Superimposing “!” and “?” just made sense.
        </p>
        <p>
          The simple design of the interrobang is probably why it’s managed to persist while other attempts at inventing punctuation have been forgotten.
        </p>
        <p>The interrobang doesn’t require any new knowledge. When you see one for the first time, its meaning is instantly clear.</p>
      </TextGroup>
      <TextGroup title='How to type an interrobang' style={{ marginTop: 64 }}>
        If you want to type an interrobang manually (rather than always copying and pasting), here’s how to do it on MacOS and iPhone:
      </TextGroup>
      <div className={styles['carousel-and-text']}>
        <Carousel
          slides={[
            <img key='slide-img-1' src={slide11} />,
            <img key='slide-img-2' src={slide12} />
          ]}
        />
        <div className={styles['carousel-text-container']}>
          <Title size={4} h={3}>How to use an interrobang on MacOS</Title>
          <List
            list={[
              `First click on this symbol ⌘ in the top right of your menu bar.
              If you can’t find it, go to System Preferences → Keyboard → Input Sources → ☑ Show input menu in menu bar.`,
              'Click Show Emoji & Symbols in the dropdown menu.',
              'The character viewer window will appear. Select Punctuation or search “interrobang”.'
            ]}
          />
        </div>
      </div>
      <div className={styles['carousel-and-text']}>
        <Carousel
          slides={[
            <img key='slide-img-1' src={slide21} />,
            <img key='slide-img-2' src={slide22} />
          ]}
        />
        <div className={styles['carousel-text-container']}>
          <Title size={4} h={3}>How to set up an interrobang shortcut on MacOS</Title>
          <List
            list={[
              'Navigate to System Preferences → Keyboard → Text. Click the plus sign +',
              'Under Replace, type the punctuation combo you’d like to autocorrect to an interrobang (like “!??” or “?!”).',
              'Under With, paste an interrobang.'
            ]}
          />
        </div>
      </div>
      <div className={styles['carousel-and-text']}>
        <Carousel
          slides={[
            <img key='slide-img-1' src={slide31} />,
            <img key='slide-img-2' src={slide32} />
          ]}
        />
        <div className={styles['carousel-text-container']}>
          <Title size={4} h={3}>How to set up an interrobang shortcut on iPhone</Title>
          <List
            list={[
              'Copy an interrobang to your clipboard.',
              'Navigate to Settings → General → Keyboard → Text Replacement.',
              'Tap + to add a shortcut.',
              'Paste your interrobang in the phrase input.',
              'Type in your desired shortcut text (like “!??” or “?!”).',
              'Tap Save.'
            ]}
          />
        </div>
      </div>
    </CopyAndPaste>
  )
}

export default Interrobang
