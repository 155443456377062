import React from 'react'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup } from 'pure-react-carousel'

import LeftArrowIcon from '../../assets/images/left-arrow.svg'
import RightArrowIcon from '../../assets/images/right-arrow.svg'

import styles from './styles.module.scss'

type SlideWithText = {
  image: React.ReactNode
  text: React.ReactNode
}

type Props = {
  slides?: React.ReactNode[]
  slidesWithText?: SlideWithText[]
  style?: React.CSSProperties
}

export const Carousel: React.FC<Props> = ({ slides = [], slidesWithText = [], style }) => {
  return (
      <div className={styles['carousel-container']} style={style}>
        <CarouselProvider
          naturalSlideWidth={927}
          naturalSlideHeight={412}
          totalSlides={slides.length || slidesWithText.length}
          className={slides.length ? '' : styles.carousel}
        >
          <div style={{ display: 'flex', alignItems: 'center', gap: 23, width: '100%' }}>
            <ButtonBack>
              <LeftArrowIcon />
            </ButtonBack>
            <Slider className={slides.length ? '' : styles['horizontal-slider']}>
              {slides.map((slide, index) => {
                return (
                  <Slide key={index} index={index}>{slide}</Slide>
                )
              })}
              {slidesWithText.map((slide, index) => {
                return (
                  <Slide innerClassName={styles['inner-slide']} className={styles['slide-with-text']} key={index} index={index}>
                    <div className={styles.image}>{slide.image}</div>
                    <div className={styles['slide-text']}>
                      {slide.text}
                    </div>
                  </Slide>
                )
              })}
            </Slider>
            <ButtonNext>
              <RightArrowIcon />
            </ButtonNext>
          </div>
          <DotGroup />
        </CarouselProvider>
      </div>
  )
}
