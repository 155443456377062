import React from 'react'
import cn from 'classnames'

import styles from './styles.module.scss'

type Props = {
  list: string[]
  className?: string
}

export const List: React.FC<Props> = ({ list, className }) => {
  return (
    <ol className={cn(styles.container, className)}>
      {list.map(i => <li key={i} className={styles.item}>{i}</li>)}
    </ol>
  )
}
